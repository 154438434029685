<template>
  <el-dialog
    :visible.sync="showDialog"
    :width="
      number_of_buttons == 1
        ? '360px'
        : number_of_buttons == 2
        ? '530px'
        : '360px'
    "
    :top="'0'"
    custom-class="warningTipsDialog"
    :destroy-on-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <span class="iconfont_Me icon-x closeIcon" @click.stop="close"></span>
    <div class="dialogTitle">
      {{ $t("tips") }}
    </div>
    <div class="dialogContent" :style="{ width: text_width }">
      <el-image
        style="width: 24px; height: 24px; margin-right: 8px; flex-shrink: 0"
        :src="
          require('../../../../../../assets/images/email/send/tipsIcon.png')
        "
        :fit="'cover'"
      ></el-image>
      <span
        v-html="showText"
        :style="{ 'word-break': langCode != 1 ? 'keep-all' : 'break-all' }"
      ></span>
    </div>
    <div class="dialogFooter">
      <div
        v-if="number_of_buttons == 1"
        class="buttonStyle"
        v-waves
        @click.stop="confirm"
        :style="{ background: buttonColor }"
      >
        <i class="iconfont_Me icon-check iconStyle"></i>
      </div>
      <div class="button_box" v-if="number_of_buttons == 2">
        <div class="button_style l_button" @click.stop="close">
          <i
            :class="
              `iconfont_Me ${
                left_button_icon ? left_button_icon : 'icon-x'
              } iconStyle`
            "
          ></i>
        </div>
        <div class="button_style r_button" @click.stop="confirm">
          <i
            :class="
              `iconfont_Me ${
                right_button_icon ? right_button_icon : 'icon-check'
              } iconStyle`
            "
          ></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "WarningTipsDialog",
  data() {
    return {
      langCode: 1,
    };
  },
  computed: {
    // 弹窗数据
    warningTipsDialog() {
      return this.$store.state.dialog.warningTipsDialog;
    },
    // 是否显示弹窗
    showDialog() {
      return this.warningTipsDialog ? this.warningTipsDialog.showDialog : false;
    },
    // 显示内容
    showText() {
      return this.warningTipsDialog ? this.warningTipsDialog.showText : "";
    },
    // 按钮颜色
    buttonColor() {
      return this.warningTipsDialog && this.warningTipsDialog.color
        ? this.warningTipsDialog.color
        : "#52BD68";
    },
    // 文本区域宽度
    text_width() {
      return this.warningTipsDialog && this.warningTipsDialog.text_width
        ? this.warningTipsDialog.text_width
        : "100%";
    },
    // 按钮数量
    number_of_buttons() {
      return this.warningTipsDialog && this.warningTipsDialog.number_of_buttons
        ? this.warningTipsDialog.number_of_buttons
        : 1;
    },
    // 左侧按钮图标
    left_button_icon() {
      return this.warningTipsDialog && this.warningTipsDialog.left_button_icon
        ? this.warningTipsDialog.number_of_buttons
        : "";
    },
    // 右侧按钮图标
    right_button_icon() {
      return this.warningTipsDialog && this.warningTipsDialog.right_button_icon
        ? this.warningTipsDialog.right_button_icon
        : "";
    },
    // from: 'email' | 'default' 调用来源
    from() {
      return this.warningTipsDialog && this.warningTipsDialog.from
        ? this.warningTipsDialog.from
        : "default";
    },
    // 确认回调
    confirmFn() {
      return this.warningTipsDialog && this.warningTipsDialog.confirmFn
        ? this.warningTipsDialog.confirmFn
        : null;
    },
    // 关闭回调
    closeFn() {
      return this.warningTipsDialog && this.warningTipsDialog.closeFn
        ? this.warningTipsDialog.closeFn
        : null;
    },
  },
  mounted() {
    this.langCode = localStorage.getItem("langCode") || 1;
  },
  methods: {
    confirm() {
      try {
        // 如果来自邮箱，则返回上一页
        if (this.from == "email") {
          this.$store.commit("setEmailHistoryComponentGoBack");
        }
        // 执行回调
        if (this.confirmFn) {
          this.confirmFn();
        }
      } catch (error) {}
      // 关闭弹窗
      this.$store.commit("setWarningTipsDialog", null);
    },
    close() {
      if (this.closeFn) {
        this.closeFn();
      }
      this.$store.commit("setWarningTipsDialog", null);
    },
  },
};
</script>
<style lang="stylus">
.warningTipsDialog
  padding: 0 !important;
  min-height: 320px;
  overflow: hidden;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    min-height: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .closeIcon
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 16px;
      color: #858585;
      cursor: pointer;
      z-index: 1;
    .dialogTitle
      width: 100%;
      padding-top: 36px;
      text-align: center;
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      flex-shrink: 0;
    .dialogContent
      flex: 1;
      min-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #000000;
      word-break: break-word;
    .dialogFooter
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
      flex-shrink: 0;
      padding: 0 24px;
      .buttonStyle
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          font-size: 24px;
          color: #FFFFFF;
      .button_box
        width: 100%;
        max-width: 356px;
        margin: auto;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button_style
          width: 172px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          .iconStyle
            font-size: 20px;
        .l_button
          border: 1px solid #52BD68;
          color: #52BD68;
        .r_button
          background: #52BD68;
          color: #FFFFFF;
</style>
