var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showDialog,
        width:
          _vm.number_of_buttons == 1
            ? "360px"
            : _vm.number_of_buttons == 2
            ? "530px"
            : "360px",
        top: "0",
        "custom-class": "warningTipsDialog",
        "destroy-on-close": true,
        "append-to-body": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        }
      }
    },
    [
      _c("span", {
        staticClass: "iconfont_Me icon-x closeIcon",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.close.apply(null, arguments)
          }
        }
      }),
      _c("div", { staticClass: "dialogTitle" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("tips")) + "\n  ")
      ]),
      _c(
        "div",
        { staticClass: "dialogContent", style: { width: _vm.text_width } },
        [
          _c("el-image", {
            staticStyle: {
              width: "24px",
              height: "24px",
              "margin-right": "8px",
              "flex-shrink": "0"
            },
            attrs: {
              src: require("../../../../../../assets/images/email/send/tipsIcon.png"),
              fit: "cover"
            }
          }),
          _c("span", {
            style: {
              "word-break": _vm.langCode != 1 ? "keep-all" : "break-all"
            },
            domProps: { innerHTML: _vm._s(_vm.showText) }
          })
        ],
        1
      ),
      _c("div", { staticClass: "dialogFooter" }, [
        _vm.number_of_buttons == 1
          ? _c(
              "div",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "buttonStyle",
                style: { background: _vm.buttonColor },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.confirm.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
            )
          : _vm._e(),
        _vm.number_of_buttons == 2
          ? _c("div", { staticClass: "button_box" }, [
              _c(
                "div",
                {
                  staticClass: "button_style l_button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("i", {
                    class: `iconfont_Me ${
                      _vm.left_button_icon ? _vm.left_button_icon : "icon-x"
                    } iconStyle`
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button_style r_button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.confirm.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("i", {
                    class: `iconfont_Me ${
                      _vm.right_button_icon
                        ? _vm.right_button_icon
                        : "icon-check"
                    } iconStyle`
                  })
                ]
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }